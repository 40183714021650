import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Ranking = () => {
  const [user, setUser] = useState(null);
  const [globalRanking, setGlobalRanking] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchUserData = async () => {
    try {
      const tgUser = window.Telegram.WebApp.initDataUnsafe.user;
      if (tgUser) {
        const response = await axios.get(`https://nazmods.net/dog/api/getuser.php?telegram_user_id=${tgUser.id}`);
        if (response.data.success) {
          setUser(response.data.currentUser);
          setGlobalRanking(response.data.globalRanking);
          setTotalUsers(response.data.totalUsers);
        }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadAdsgramScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://sad.adsgram.ai/js/sad.min.js';
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error('Failed to load Adsgram script'));
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    fetchUserData();
    loadAdsgramScript().then(() => {
      console.log('Adsgram script loaded successfully');
    }).catch(error => {
      console.error('Error loading Adsgram script:', error);
    });
  }, []);

  const handleAdCompletion = async (result) => {
    if (result.done) {
      alert('You received 50 DOGS on your balance');
      await updateUserBalance(50);
      fetchUserData();
    } else {
      alert('Ad was not watched till the end.');
    }
  };

  const handleAdError = (result) => {
    console.error('Ad error:', result.description);
    alert(`Failed to load ad: ${result.description || 'Unknown error'}`);
  };

  const updateUserBalance = async (amount) => {
    try {
      const tgUser = window.Telegram.WebApp.initDataUnsafe.user;
      const response = await axios.post('https://nazmods.net/dog/api/update_balance.php', {
        telegram_user_id: tgUser.id,
        amount: amount
      });
      if (!response.data.success) {
        alert('Failed to update user balance.');
      }
    } catch (error) {
      console.error('Error updating user balance:', error);
    }
  };

  const initAd = async () => {
    try {
      const tgUser = window.Telegram.WebApp.initDataUnsafe.user;
      const AdController = window.Adsgram.init({ blockId: "863", userId: tgUser.id });

      AdController.addEventListener('onReward', handleAdCompletion);
      AdController.addEventListener('onError', handleAdError);

      const canLoadAd = await AdController.load();
      console.log('Load result:', canLoadAd);

      if (canLoadAd) {
        const showResult = await AdController.show();
        console.log('Show result:', showResult);
        handleAdCompletion(showResult);
      } else {
        alert('No ad available to show at the moment.');
      }

      AdController.removeEventListener('onReward', handleAdCompletion);
      AdController.removeEventListener('onError', handleAdError);
    } catch (err) {
      console.error('Ad loading/playing error:', err);
      alert(`Failed to load ad: ${err.description || 'Unknown error'}`);
    }
  };

  return (
    <div className="container mx-auto p-4 text-center bg-black text-white min-h-screen">
      <h1 className="text-center text-3xl font-bold mb-4">Telegram Wall of Fame</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {user && (
            <div id="current-user" className="bg-gray-800 p-4 rounded-lg mb-4 flex items-center">
              <div className="h-12 w-12 bg-gray-600 rounded-full flex items-center justify-center">
                <span className="text-xl font-bold text-white">{user.user_id.toString().slice(0, 2).toUpperCase()}</span>
              </div>
              <div className="ml-4">
                <p className="font-bold">#{user.user_id}</p>
                <p className="text-gray-400">{Math.floor(user.balance).toLocaleString()} DOGS</p>
              </div>
              <div className="ml-auto">
                <p className="text-gray-400">Rank: #{globalRanking.findIndex(u => u.user_id === user.user_id) + 1}</p>
              </div>
            </div>
          )}
          <button className="bg-blue-500 text-white w-full py-2 rounded-lg mb-4" onClick={initAd}>⭐ Boost score</button>
          <h2 className="text-xl font-bold mb-4">{totalUsers.toLocaleString()} holders</h2>
          <div id="global-ranking">
            {globalRanking.map((rank, index) => (
              <div key={rank.user_id} className="flex items-center mb-4">
                <div className={`h-12 w-12 ${index === 0 ? 'bg-pink-500' : index === 1 ? 'bg-blue-500' : index === 2 ? 'bg-green-500' : 'bg-gray-500'} rounded-full flex items-center justify-center`}>
                  <span className="text-xl font-bold text-white">{rank.user_id.toString().slice(0, 2).toUpperCase()}</span>
                </div>
                <div className="ml-4">
                  <p className="font-bold">#{rank.user_id}</p>
                  <p className="text-gray-400">{rank.balance.toLocaleString()} DOGS</p>
                </div>
                <div className="ml-auto">
                  {index < 3 ? (
                    <span className={`fas fa-medal ${index === 0 ? 'text-yellow-500' : index === 1 ? 'text-gray-400' : 'text-orange-500'}`}></span>
                  ) : (
                    <span className="text-gray-400">#{index + 1}</span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      <div className="fixed bottom-0 left-0 w-full bg-gray-800">
        <nav className="flex justify-between p-4">
          <Link to="/" className="nav-item flex flex-col items-center justify-center text-white">
            <i className="fas fa-home text-xl mb-1"></i>
            <span>Home</span>
          </Link>
          <Link to="/ranking" className="nav-item active flex flex-col items-center justify-center text-blue-500">
            <i className="fas fa-chart-bar text-xl mb-1"></i>
            <span>Leaderboard</span>
          </Link>
          <Link to="/invite" className="nav-item flex flex-col items-center justify-center text-white">
            <i className="fas fa-users text-xl mb-1"></i>
            <span>Friends</span>
          </Link>
        </nav>
      </div>
    </div>
  );
};

export default Ranking;
