/*global Telegram*/
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Invite = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const tgUser = window.Telegram.WebApp.initDataUnsafe.user;
        if (tgUser) {
          const response = await axios.get(`https://nazmods.net/dog/api/getuser.php?telegram_user_id=${tgUser.id}`);
          if (response.data.success) {
            setUser(response.data.currentUser);
          }
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleInviteClick = () => {
    if (user) {
      Telegram.WebApp.openTelegramLink(`https://t.me/share/url?url=${encodeURIComponent(user.referral_link)}&text=${encodeURIComponent('Join me and get more DOGS!')}`);
    }
  };

  return (
    <div className="bg-black text-white min-h-screen flex flex-col">
      <div className="container mx-auto p-4 text-center flex-grow">
        <h1 className="text-3xl font-bold mb-4">Invite friends and get more DOGS</h1>
        <div className="mb-4">
          <img
            src="https://cache.tonapi.io/imgproxy/R-9iv8csp1jZ9ymWn4dvcnYC_z3seU2dCrDdU2whQn4/rs:fill:200:200:1/g:no/aHR0cHM6Ly9yYXcuZ2l0aHVidXNlcmNvbnRlbnQuY29tL1Jlc2lzdGFuY2UtRG9nL3Jlc2lzdGFuY2UtZG9nL21haW4vcmVzaXN0YW5jZS1kb2cud2VicA.webp"
            alt="Dog"
            className="mx-auto"
          />
        </div>
        <p className="mb-4">Tap on the button to invite your friends</p>
        <button onClick={handleInviteClick} className="bg-white text-black w-full py-2 rounded-lg mb-4">
          Invite friends
        </button>
      </div>
      <div className="fixed bottom-0 left-0 w-full bg-gray-800">
        <nav className="flex justify-between p-4">
          <Link to="/" className="nav-item  flex flex-col items-center justify-center text-white">
            <i className="fas fa-home text-xl mb-1"></i>
            <span>Home</span>
          </Link>
          <Link to="/ranking" className="nav-item  flex flex-col items-center justify-center text-blue-500">
            <i className="fas fa-chart-bar text-xl mb-1"></i>
            <span>Leaderboard</span>
          </Link>
          <Link to="/invite" className="nav-item active flex flex-col items-center justify-center text-white">
            <i className="fas fa-users text-xl mb-1"></i>
            <span>Friends</span>
          </Link>
        </nav>
      </div>
    </div>
  );
};

export default Invite;
