import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'tailwindcss/tailwind.css';
import { Link } from 'react-router-dom';


const Home = () => {
  const [balance, setBalance] = useState('Loading...');
  const [ageBalance, setAgeBalance] = useState('Loading...');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        window.Telegram.WebApp.ready();
        window.Telegram.WebApp.expand();

        const tgUser = window.Telegram.WebApp.initDataUnsafe?.user;
        if (tgUser) {
          const response = await axios.get(`https://nazmods.net/dog/api/getuser.php?telegram_user_id=${tgUser.id}`, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          if (response.data.success) {
            const userDetails = response.data.currentUser;
            setBalance(`${Math.floor(userDetails.balance)} DOGS`);
            setAgeBalance(`+${response.data.age_balance} DOGS`);
          } else {
            setBalance("User not found.");
            setAgeBalance("User not found.");
          }
        }
      } catch (error) {
        setBalance("Error loading balance.");
        setAgeBalance("Error loading balance.");
        console.error('Error fetching user balance:', error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <div className="bg-black text-white min-h-screen">
      <div className="container mx-auto p-4">
        <div className="text-center mb-8">
          <img src="https://cache.tonapi.io/imgproxy/R-9iv8csp1jZ9ymWn4dvcnYC_z3seU2dCrDdU2whQn4/rs:fill:200:200:1/g:no/aHR0cHM6Ly9yYXcuZ2l0aHVidXNlcmNvbnRlbnQuY29tL1Jlc2lzdGFuY2UtRG9nL3Jlc2lzdGFuY2UtZG9nL21haW4vcmVzaXN0YW5jZS1kb2cud2VicA.webp" alt="Logo" className="mx-auto h-16" />
          <h1 id="user-balance" className="text-4xl font-bold mt-4">{balance}</h1>
        </div>
        <div className="bg-gray-800 p-4 rounded-lg mb-8">
          <h2 className="text-2xl font-bold">DOGS COMMUNITY</h2>
          <p className="text-gray-400">Home for Telegram OGs</p>
          <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg">Join</button>
        </div>
        <div>
          <h2 className="text-xl font-bold mb-4">Your rewards</h2>
          <div className="flex items-center mb-4">
            <div className="h-10 w-10 bg-gray-600 rounded-full flex items-center justify-center">
              <i className="fas fa-star text-white"></i>
            </div>
            <div className="ml-4">
              <p className="font-bold">Account age</p>
              <p id="account-age" className="text-gray-400">{ageBalance}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 w-full bg-gray-800">
        <nav className="flex justify-between p-4">
          <Link to="/" className="nav-item active flex flex-col items-center justify-center text-white">
            <i className="fas fa-home text-xl mb-1"></i>
            <span>Home</span>
          </Link>
          <Link to="/ranking" className="nav-item  flex flex-col items-center justify-center text-blue-500">
            <i className="fas fa-chart-bar text-xl mb-1"></i>
            <span>Leaderboard</span>
          </Link>
          <Link to="/invite" className="nav-item flex flex-col items-center justify-center text-white">
            <i className="fas fa-users text-xl mb-1"></i>
            <span>Friends</span>
          </Link>
        </nav>
      </div>
    </div>
  );
};

export default Home;
